var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    [
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            { staticClass: "mb-2" },
            [
              _c(
                "b-col",
                {
                  staticClass: "px-0 position-relative",
                  attrs: { cols: "12", sm: "6" }
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "position-absolute w-100 h-100 d-flex align-items-center justify-content-center text-white font-weight-bolder"
                    },
                    [_vm._v(_vm._s(_vm.date))]
                  ),
                  _c("img", {
                    staticClass: "img-fluid",
                    attrs: {
                      src: require("../assets/prensa/date_" +
                        _vm.color1 +
                        ".png"),
                      alt: ""
                    }
                  })
                ]
              ),
              _c(
                "b-col",
                { staticClass: "pt-3 pt-sm-0", attrs: { cols: "6", sm: "6" } },
                [
                  _c(
                    "h4",
                    {
                      staticClass: "text-left",
                      style: "border-color:#" + _vm.color2
                    },
                    [_vm._v(_vm._s(_vm.media))]
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "p-0 image-container",
                  attrs: { cols: "12", sm: "6" }
                },
                [
                  _c("img", {
                    staticClass: "img-fluid modified-image",
                    attrs: { src: _vm.requireImage(_vm.photo) }
                  })
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass:
                    "p-0 pl-3 pt-3 pt-sm-0 text-left d-flex flex-column justify-content-between",
                  attrs: { cols: "12", sm: "6" }
                },
                [
                  _c("div", [
                    _c("p", { staticClass: "m-0" }, [
                      _c("b", [_vm._v(_vm._s(_vm.title))])
                    ]),
                    _c("p", { domProps: { innerHTML: _vm._s(_vm.abstract) } })
                  ]),
                  _c("div", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.link,
                          target: "_blank",
                          rel: "noopener noreferrer"
                        }
                      },
                      [_vm._v(_vm._s(_vm.button))]
                    )
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }