var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("header", [
      _c("h1", { staticClass: "main-title" }, [
        _vm._v(_vm._s(_vm.$tc("press.title")))
      ])
    ]),
    _c("div", { staticClass: "desc position-relative" }, [
      _c("h2", [_vm._v(" " + _vm._s(_vm.$tc("press.desc")) + " ")]),
      _c("img", {
        staticClass: "position-absolute h-75 d-none d-md-block",
        staticStyle: { left: "0", top: "10%" },
        attrs: { src: require("@/assets/prensa/u.png"), alt: "" }
      })
    ]),
    _c(
      "section",
      [
        _c("div", { staticClass: "media position-relative text-white" }, [
          _c(
            "h2",
            {
              staticClass:
                "p-0 m-0 position-absolute w-100 h-100 d-flex align-items-center justify-content-center font-weight-bolder"
            },
            [
              _vm._v(" " + _vm._s(_vm.$tc("press.title2", 1)) + " "),
              _c("br"),
              _vm._v(" " + _vm._s(_vm.$tc("press.title2", 2)) + " ")
            ]
          ),
          _c("img", {
            staticClass: "img-fluid",
            attrs: { src: require("@/assets/prensa/media.jpg"), alt: "media" }
          })
        ]),
        _c(
          "b-container",
          { staticClass: "mt-5" },
          [
            _c(
              "b-row",
              _vm._l(_vm.notas, function(nota, index) {
                return _c(
                  "b-col",
                  {
                    key: index,
                    staticClass: "py-4",
                    attrs: { cols: "12", lg: "6" }
                  },
                  [
                    _c("Nota", {
                      attrs: {
                        date: nota.date,
                        media: nota.media,
                        title: _vm.lang == "es" ? nota.titleES : nota.titleEN,
                        abstract:
                          _vm.lang == "es" ? nota.abstractES : nota.abstractEN,
                        button: _vm.$tc("press." + nota.type),
                        photo: nota.photo,
                        link: nota.link,
                        color1:
                          nota.type == "note"
                            ? "0000ff"
                            : nota.type == "interview"
                            ? "ff00ff"
                            : "baff00",
                        color2:
                          nota.type == "note"
                            ? "ff00ff"
                            : nota.type == "interview"
                            ? "baff00"
                            : "0000ff"
                      }
                    })
                  ],
                  1
                )
              }),
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }