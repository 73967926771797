// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/prensa/header_prensa.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\nheader[data-v-f735401a] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  padding: 15% 0 30% 0;\n  color: #baff43;\n}\nh2[data-v-f735401a]{\n  font-size: 1.5rem;\n}\n.desc[data-v-f735401a]{\n  padding: 1em;\n  text-align: center;\n}\n@media screen and (max-width: 425px){\nheader[data-v-f735401a]{\n    margin-top: 0;\n}\nh2[data-v-f735401a]{\n    font-size: 1.2rem;\n}\n}\n@media screen and (min-width: 768px) {\n.desc[data-v-f735401a] {\n      padding: 3em 3em 3em 15em;\n      text-align: left;\n}\nh2[data-v-f735401a]{\n      font-size: 2rem;\n}\n}\n@media screen and (min-width: 992px) {\n.desc[data-v-f735401a] {\n      padding: 4em 4em 4em 15em;\n      text-align: left;\n}\nh2[data-v-f735401a]{\n      font-size: 2.5rem;\n}\n}\n@media screen and (min-width: 1200px) {\n.desc[data-v-f735401a] {\n      padding: 5em 5em 5em 15em;\n      text-align: left;\n}\nh2[data-v-f735401a]{\n      font-size: 2.5rem;\n}\n}\n\n\n", ""]);
// Exports
module.exports = exports;
